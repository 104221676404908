import { useRouter } from 'next/router';
import {
  Fragment,
  useContext,
  useState
} from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Logo from '../../01_atoms/Logo/Logo';
import AgentPopup from '../../02_molecules/AgentPopup';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getAuthClient, SESSION_TOKEN } from '../../../utils/auth.js';
import CurrentPageContext from '../../context/CurrentPageData';
import Hidden from '@material-ui/core/Hidden';
import CustomMuiModal from '../../02_molecules/CustomMuiModal/index.js';
import WebformEmbed from '../../01_atoms/WebformEmbed';
import useSWR from 'swr';
import { fetchAPIRequestData } from '../../../api/global';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    container: {
      display: 'flex',
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.grey[700],
    padding: '1.125rem 0',
    height: 103,
    marginRight: 0,
    width: '100%',
    maxWidth: '100vw',
    left: 0,
  },
  title: {
    flexGrow: 1,
  },
  menuIcon: {
    transform: 'scale(1.9,1.75)',
    margin: '0 0 0 1.5rem',
    "& hide": {
      display: 'none',
      transform: 'scale(1.9,1.75)',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& a": {
      fontWeight: 700,
      margin: '1rem 0',
      "&:hover": {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.text.primary,
        "& svg": {
          color: theme.palette.text.primary,
        }
      },
    },
  },
  accordionSummary: {
    textTransform: 'uppercase',
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.primary,
      "& svg": {
        color: theme.palette.text.primary,
      }
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.warning.main,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 400,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: "15px 24px 24px",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  mainMenu: {
    padding: '0 !important',
    

    "& ul": {
      padding: '0 !important',
      color: 'white',

    },
  },
  mainMenuItem: {
    padding: '0 !important',
    "& span": {
      display: "block",
      marginBottom: 0,
    }
  },
  drawerClose: {
    color: theme.palette.warning.main,
  },
  appBarAccordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.warning.main,
    "& button:hover": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.primary,
      "& svg": {
        color: theme.palette.text.primary,
      }
    },
    "& ul a": {
      paddingLeft: 32,
    },
    "&:before": {
      content: 'none !important',
    },
    "&:after": {
      content: 'none !important',
    },
    "& p": {
      marginBottom: 0,
    },
    "& svg":
    {
      color: theme.palette.warning.main,
    },
    "& .second-level": {
      padding: '0 1rem',
      "& p": {
        color: 'white',
      },
      "& a": {
        padding: '0 1.5rem'
      },
    },
  },
  accordionMenuDetails: {
    padding: 0,
  },
  accordionList: {
    display: 'block',
    width: '100%',
  },
  headerContact: {
    "& a": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
  },
}));

export default function TemporaryDrawer(props) {
  const router = useRouter();
  const auth = getAuthClient();
  let { menu } = props;
  const classes = useStyles();
  const theme = useTheme();
  const logo = '/assets/eaton-realty-logo-white.png';

  const { authUserCtx, setAuthUserCtx } = useContext(CurrentPageContext)
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedLvl2, setExpandedLvl2] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeLvl2 = (panel) => (event, isExpanded) => {
    setExpandedLvl2(isExpanded ? panel : false);
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let isLoggedIn = auth.getLocalStorage('token');

  // Add this check for "gmuser" storage here, so it can be expired, if necessary,
  // on page load:
  auth.getLocalStorage('agent');

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const webform_19 = (
    <WebformEmbed frameMinHeight={`800px`} key={`iframe_webform_19`}
      name={`contact_us`}
      webformid={19} html_id={`contact_us`}></WebformEmbed>
  );

  let menu_header = useSWR(!(menu?.['#items']) ? [`/menu/header`, {}, {type: 'decouple'}, null, null] : null, fetchAPIRequestData);
  if (!(menu?.['#items']) && menu_header?.data?.page_data?.['#items']) {
    menu = menu_header?.data?.page_data;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={0} position="fixed" className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      >
        <Container>
          <Toolbar>
            <Logo logoPriority alt="Eaton Realty, Inc." name={logo}></Logo>
            <Box className={classes.headerContact}
              display={{ xs: 'none', sm: 'block' }}>
              <Hidden mdUp>
                <CustomMuiModal modalFullWidth={true}
                  modalHeader="Contact Eaton Realty"
                  modalButtonStrongText="Contact"
                  modalButtonText="Us"
                  modalButtonColor="primary"
                  modalContent={webform_19}
                />
              </Hidden>
              <Hidden smDown>
                <AgentPopup></AgentPopup>
              </Hidden>
            </Box>

            <IconButton
              style={{ color: theme.palette.warning.main }}
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer('right', true)}
              className={classes.menuIcon + clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>

      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <Drawer className={classes.drawer}
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.drawerClose}
            onClick={toggleDrawer('right', false)}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> :
              <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        {menu?.['#items'] && (
          <List className={classes.mainMenu}>
            {
              Object.entries(menu['#items']).map(([key, item]) => {
                let inner_lvl1 = [];
                let uniqueID = key.substring(key.length - 7);

                if (item.below && (item.below.length > 0 || Object.keys(item.below).length)) {
                  for (const [key1, item1] of Object.entries(item.below)) {
                    // Object.entries(item.below).map(([key1, item1]) => {
                    if (key1.includes('menu_link_content:')) {
                      let inner_lvl2;

                      if (item1.below && (item1.below.length > 0 || Object.keys(item1.below).length)) {
                        inner_lvl2 = [];
                        for (const [key2, item2] of Object.entries(item1.below)) {
                          // Object.entries(item1.below).map(([key2, item2]) => {
                          if (key2.includes('menu_link_content:')) {
                            inner_lvl2.push({
                              title: item2.title,
                              href: item2.url,
                            });
                          }
                        }
                      }

                      inner_lvl1.push({
                        title: item1.title,
                        href: item1.url,
                        inner_items: (inner_lvl2?.length > 0 && inner_lvl2) || null,
                        id: key1.substring(key1.length - 7)
                      });
                    }
                  }
                }

                return (
                  <Fragment key={`frag-${key}`}>
                    {(!item.below || item.below.length < 1) &&
                      <ListItem key={`nav-${key}`} button
                        component="a" href={item.url}>
                        <ListItemText primary={item.title}
                          className={classes.mainMenuItem}></ListItemText>
                      </ListItem>
                    }
                    {inner_lvl1.length > 0 &&
                      <Accordion key={`acc-${key}`}
                        expanded={expanded === key}
                        onChange={handleChange(key)}
                        className={classes.appBarAccordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${uniqueID}-content`}
                          id={`${uniqueID}-header`}
                        >
                          <Typography
                            className={classes.accordionHeading}>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionMenuDetails}>
                          <List className={classes.accordionList}>
                            {/*<ListItem button component="a" href={item.url} key={`drop-${key}`}>*/}
                            {/*  <ListItemText primary={item.title}*/}
                            {/*    className={classes.mainMenuItem}></ListItemText>*/}
                            {/*</ListItem>*/}
                            {inner_lvl1.map((ind_l, k) => {
                                return (
                                    <Fragment key={`frag-lvl2-${ind_l.id}`}>
                                      {(!ind_l.inner_items || ind_l.inner_items.length < 1) &&
                                      <ListItem key={`nav-lvl2-${key}`} button component="a" href={ind_l.href}>
                                        <ListItemText primary={ind_l.title} className={classes.mainMenuItem}></ListItemText>
                                      </ListItem>
                                      }

                                      {ind_l.inner_items?.length > 0 &&
                                      <Box className='second-level'>
                                        <Accordion key={`acc-lvl2-${ind_l.id}`}
                                                 expanded={expandedLvl2 === ind_l.id}
                                                 onChange={handleChangeLvl2(ind_l.id)}
                                                 className={classes.appBarAccordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls={`lvl2-content`}
                                            id={`lvl2-header`}
                                        >
                                          <Typography className={classes.accordionHeading}>{ind_l.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionMenuDetails}>
                                          <List className={classes.accordionList}>
                                            {/*<ListItem button component="a" href={ind_l.href} key={`drop-lvl2-${ind_l.id}`}>*/}
                                            {/*  <ListItemText primary={ind_l.title} className={classes.mainMenuItem}></ListItemText>*/}
                                            {/*</ListItem>*/}
                                            {ind_l?.inner_items?.map((ind_l2, k2) => {
                                                return (
                                                    <ListItem button
                                                              component="a"
                                                              href={ind_l2.href}
                                                              key={`drop-lvl2-${k2}`}>
                                                      <ListItemText
                                                          primary={ind_l2.title}
                                                          className={classes.mainMenuItem}></ListItemText>
                                                    </ListItem>
                                                );
                                              })
                                            }
                                          </List>
                                        </AccordionDetails>
                                      </Accordion>
                                      </Box>

                                      }


                                      {/*</ListItem>*/}
                                    </Fragment>
                                );
                              })
                            }
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    }
                  </Fragment>
                );
              })
            }
            <ListItem key={`nav-usr-account`} button component="a"
              href={`/account`}>
              <ListItemText primary={'My Account'}
                className={classes.mainMenuItem}></ListItemText>
            </ListItem>
            {isLoggedIn &&
              <ListItem key={`nav-usr-logout`} button component="a" href={`#`}
                onClick={() => auth.logout().then(() => {
                  setAuthUserCtx({});
                  isLoggedIn = false;
                  router.push('/');
                })}>
                <ListItemText primary={'Log Out'}
                  className={classes.mainMenuItem}></ListItemText>
              </ListItem>
            }
          </List>
        )}
      </Drawer>
    </div>
  );
}
