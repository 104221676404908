import {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

import classNames from 'classnames';

function CustomMuiModal(props) {
  let {
    modalContent,
    modalHeader,
    modalButtonStrongText,
    modalTrigger,
    triggerStyle,
    modalFullWidth,
    modalButtonText,
    modalButtonColor,
    modalButtonVariant
  } = props;

  modalTrigger = modalTrigger || 'button';
  modalButtonVariant = modalButtonVariant || 'contained';

  // getModalStyle is not a pure function, we roll the style only on the first
  // render
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`mui-modal-popup`}>
      {typeof modalTrigger === 'string' && modalTrigger == 'button' &&
      <Button disableElevation={true} variant={modalButtonVariant}
              color={modalButtonColor} onClick={handleOpen}>
        {modalButtonStrongText &&
        <strong>{modalButtonStrongText}&nbsp;</strong>}{modalButtonText}
      </Button>
      }
      {typeof modalTrigger === 'string' && modalTrigger == 'link' &&
      <Link href="#" style={triggerStyle} onClick={handleOpen}>
        {modalButtonStrongText &&
        <strong>{modalButtonStrongText}&nbsp;</strong>}{modalButtonText}
      </Link>
      }
      <Modal
        onClick={event => event.stopPropagation()}
        onMouseDown={event => event.stopPropagation()}
        open={open}
        onClose={handleClose}
        aria-labelledby="eaton-modal-title"
        aria-describedby="eaton-modal-description"
      >
        <div
          className={classNames(modalFullWidth ? 'modalContentFull' : 'modalContent')}>
          <Link href="#" className={`modalClose`}
                onClick={handleClose}><CloseIcon/></Link>
          {modalHeader &&
          <Typography variant="h4" component="h2" id={`eaton-modal-title`}
                      className={`eaton-modal-title`}><strong>{modalHeader}</strong></Typography>
          }
          {modalContent &&
          <p id="eaton-modal-description" className="eaton-modal-description">
            {modalContent}
          </p>
          }
          <div className="modal-content">
            {props?.children}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CustomMuiModal;
