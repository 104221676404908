import React from 'react';
import dynamic from 'next/dynamic';
import Grid from '@material-ui/core/Grid';
import common from 'superagent-jsonapify/common';
import request from '../utils/request';
import {metadata, applyEntityTransform} from '../utils/transforms';
import SubRequests from '../utils/subrequest';

export const componentList = {
  MatterportVideo: dynamic(() => import(`../components/01_atoms/Media/Matterport`), {ssr: false}),
  CustomMuiModal: dynamic(() => import(`../components/02_molecules/CustomMuiModal`), {ssr: false}),
  GutenbergMuiGrid: dynamic(() => import(`../components/01_atoms/GutenbergMuiGrid`)),
  CustomMuiMediaSlider: dynamic(() => import(`../components/03_organisms/CarouselMuiSlider`), {ssr: false}),
  CustomMuiCarousel: dynamic(() => import(`../components/03_organisms/CardCarousel`), {ssr: false}),
  CustomMuiButton: dynamic(() => import(`../components/01_atoms/CustomMuiButton`)),
  CustomMuiAccordion: dynamic(() => import(`../components/02_molecules/CustomMuiAccordion`)),
  CustomMuiTabs: dynamic(() => import(`../components/02_molecules/CustomMuiTabs`)),
  CustomMuiCTA: dynamic(() => import(`../components/02_molecules/CustomMuiCTA`)),
  CustomMuiExpandCTA: dynamic(() => import(`../components/02_molecules/CustomMuiExpandCTA`)),
  EatonListingCard: dynamic(() => import(`../components/03_organisms/ListingCard/ListingCard`)),
  EatonFeaturedArticle: dynamic(() => import(`../components/02_molecules/FeaturedArticle/FeaturedArticle`)),
  EatonTeaserArticle: dynamic(() => import(`../components/02_molecules/TeaserArticle/TeaserArticle`)),
  EatonCardArticle: dynamic(() => import(`../components/02_molecules/ArticleGrid`)),
  EatonAgentCard: dynamic(() => import(`../components/02_molecules/AgentCard`)),
  EatonAgentSlide: dynamic(() => import(`../components/02_molecules/AgentSlide/AgentSlide`)),
  ContentList: dynamic(() => import(`../components/02_molecules/ContentList`)),
  LocationBlockList: dynamic(() => import(`../components/02_molecules/LocationBlockTeaser/LocationBlockTeaser`)),
  LocationBlockTeaser: dynamic(() => import(`../components/02_molecules/LocationTeaser/index.js`)),
  TestimonialReview: dynamic(() => import(`../components/02_molecules/TenantReview`)),
  AgentReviewSingle: dynamic(() => import('../components/02_molecules/Testimonial/Testimonial').then((mod) => mod.Testimonial)),
  TestimonialCarousel: dynamic(() => import(`../components/02_molecules/Testimonial/Testimonial`)),
  ReactRESTView: dynamic(() => import(`../components/01_atoms/ReactRESTView`)),
  ReactWebformEmbed: dynamic(() => import(`../components/01_atoms/WebformEmbed`), {ssr: false}),
  GutenbergSectionWrap: dynamic(() => import(`../components/01_atoms/GutenbergSectionWrap`)),
}

export function chooseRandomList(arr, num = 1) {
  const res = [];
  for (let i = 0; i < num; i++) {
    const random = Math.floor(Math.random() * arr.length);
    if (arr[random] && res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    // i++;
  }
  return res;
}

export function parseJSONAPI(text) {
  var parse = common.parse;
  return parse(text);
}

export const parseGutenbergReact = (key, block, skipRestRequests) => {
  let res = null, orig_type = block?.type;

  if (orig_type?.includes('rendered_html')) {
    res = (<div key={`inner-${key}`} className={`para-${orig_type}`}
                dangerouslySetInnerHTML={{__html: `<div class="inner-html">${block.data}</div>`}}></div>);
  }
  else if (orig_type) {
    if (orig_type == 'GutenbergCover') {
      orig_type = 'GutenbergSectionWrap';
    }
    let DynamicComp = componentList[orig_type];
    if (DynamicComp) {
      let props = (block.props && {...block.props}) || {};
      if (block.props?.length > 1) {
        res = (
          <>
            <Grid spacing={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
            >
              {block.props.map((v, i) => {
                v.gutenberg = true;
                return (
                  <Grid item key={`grid-mui-el-${i}`}>
                    <DynamicComp {...v} key={`inner-${orig_type}-${key}`}
                                 className={`para-${orig_type}`}
                                 block={block} skipData={skipRestRequests}/>
                  </Grid>
                );
              })
              }
            </Grid>
          </>
        );
      }
      else {
        props = (block.props?.length && {...block.props[0]}) || {};
        props.gutenberg = true;
        res = (<DynamicComp {...props} skipData={skipRestRequests} block={block} className={`para-${orig_type}`}
                            key={`inner-${orig_type}-${key}`}/>);
      }
    }
  }
  return res;
};

export function getGlobalElementsLookup(path, ent_type) {
  // @see: https://www.lullabot.com/articles/incredible-decoupled-performance-with-subrequests
  const sub = new SubRequests(`${process.env.NEXT_PUBLIC_BACKEND_URL}/subrequests?_format=json`);
  const obj_path = ent_type === 'term' ? `/taxonomy/term/{{pageroute.body@$.entity.id}}` : `/{{pageroute.body@$.entity.type}}/{{pageroute.body@$.entity.id}}`;

  sub.add({
    requestId: "pagemenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/header`,
  });

  sub.add({
    requestId: "footermenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/footer`,
  });

  sub.add({
    requestId: "pageroute",
    uri: `/router/translate-path?_format=json&path=${path}`,
  });

  sub.add({
    requestId: "pagemeta",
    // uri:
    // `${process.env.DECOUPLED_PREFIX}/metatag?path=/{{pageroute.body@$.jsonapi.resourceName}}/${path}`,
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/metatag?_format=json&path=${obj_path}`,
    waitFor: ["pageroute"],
  });

  return sub;
}


export function getGlobalElements(obj) {
  // @see: https://www.lullabot.com/articles/incredible-decoupled-performance-with-subrequests
  const sub = new SubRequests(`${process.env.NEXT_PUBLIC_BACKEND_URL}/subrequests?_format=json`);
  const obj_path = (obj.type === 'taxonomy_term') ? `/taxonomy/term/${obj.id}` : `/${obj.type}/${obj.id}`;

  sub.add({
    requestId: "pagemenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/header`,
  });
  sub.add({
    requestId: "footermenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/footer`,
  });
  sub.add({
    requestId: "pagemeta",
    uri:
  `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/metatag?_format=json&path=${obj_path}`,
  });

  return sub;
}

/**
 * Returns internal info about the page.
 *
 * @param path
 *   Drupal path to look up.
 *
 * @returns {Promise<any>}
 */
export const getPageInfo = path => new Promise((resolve, reject) => {
  request('tld')
      .get(`/router/translate-path?_format=json&path=${path}`)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => resp.statusCode)
      .then((response) => {
        resolve({
          page: response.statusCode === 200 ? response.body : {},
          statusCode: response.statusCode,
        });
      })
      .catch((error) => {
        console.error('Could not fetch page info.', error);
        reject(error);
      });
});

/**
 * Returns internal info about the page (with authenticated account
 * permissions).
 *
 * @param url
 *   Drupal API URL to look up.
 *
 * @returns {Promise<any>}
 */
export async function getPageDataAuthenticated(url, query, opts = {}) {
  query = query || {};
  let q = {};

  if (url.includes('/users')) {
    q = {
      'include': 'image,image.imageFile,roles',
      'fields[roles]': 'label,internalId',
      'fields[images]': 'imageFile',
      // 'fields[listings]': 'internalId,path,title,price,mlsNum,saleType,saleStatus',
      'fields[files]': 'uri',
      ...query
    };
  }

  const res = await fetchAPIRequestData(url, q, opts);
  return res;
}


/**
 * Returns internal info about the page.
 *
 * @param url
 *   Drupal API URL to look up.
 *
 * @returns {Promise<any>}
 */
export async function getIndividualPageData(url, query, opts) {
  query = query || {};
  opts = opts || {};
  let q = {};

  if (url.includes('/articles')) {
    q = {
      'include': 'image,image.imageFile,category,video,video.videoFile',
      'fields[images]': 'imageFile',
      'fields[videosLocal]': 'videoFile',
      'fields[videosRemote]': 'videoFile',
      'fields[files]': 'uri',
      ...query
    };
  }
  else if (url.includes('/boundaryPages')) {
    q = {
      'include': 'city_term,comm_term,neighbor_term,school_term,special_loc',
      'fields[cities]': 'internalId,name,vocab,geo',
      'fields[communities]': 'internalId,name,vocab,geo',
      'fields[neighborhoods]': 'internalId,name,vocab,geo',
      'fields[schools]': 'internalId,name,vocab,geo',
      'fields[featuredLocations]': 'internalId,name,vocab,geo',
      ...query
    };
  }
  else if (url.includes('/pages')) {
    q = {
      ...query
    };
  }
  else if (url.includes('/testimonials')) {
    q = {
      'include': 'image,image.imageFile,category',
      'fields[images]': 'imageFile',
      'fields[files]': 'uri',
      ...query
    };
  }
  else if (url.includes('/users')) {
    q = {
      'include': 'image,image.imageFile,roles',
      'fields[roles]': 'label,internalId',
      'fields[images]': 'imageFile',
      'fields[files]': 'uri',
      ...query
    };
    if (opts) {
      if (opts.agent) {
        q = {...q,
          'include': 'image,image.imageFile,roles,boundaryRef,boundaryRef.city_term,boundaryRef.neighbor_term,boundaryRef.comm_term,boundaryRef.school_term,boundaryRef.special_loc',
          'fields[boundaryPages]': 'id,internalId,contentType,path,title,body,page_type,summary,city_term,neighbor_term,comm_term,school_term,special_loc',
          'fields[cities]': 'id,internalId,name',
          'fields[communities]': 'id,internalId,name',
          'fields[neighborhoods]': 'id,internalId,name',
          'fields[featuredLocations]': 'id,internalId,name',
          'fields[schools]': 'id,internalId,name',
        }
      }
      else if (opts.lead) {}
    }
  }
  else if (url.includes('/yardSigns')) {
    q = {
      'include': 'listingRef',
      'fields[listings]': 'internalId,path',
      ...query
    };
  }
  else if (url.includes('/cities') || url.includes('/neighborhoods')) {
    q = {
      // 'include': 'slides,slides.imageFile',
      // 'fields[images]': 'imageFile',
      // 'fields[files]': 'uri',
      ...query
    };
  }

  const res = await fetchAPIRequestData(url, q);
  return res;
}

/**
 * Returns internal info about the page.
 *
 * @param string url
 *   Drupal API URL to look up.
 * @param object query
 *   Drupal API query object.
 * @param object opts
 *   Options as an object {type: "api|tld|decoupled|solr|false"}.
 * @param integer pageLimit
 *   Query: limit of returned results.
 * @param integer pageOffset
 *   Query: page offset.
 *
 * @returns {Promise<any>}
 */
export const fetchAPIRequestData = async (url, query, opts, pageLimit, pageOffset) => new Promise((resolve, reject) => {
  const pref = opts?.type || false;
  const auth = opts?.auth || false; // "auth" should contain oAuth access token.

  if (pageLimit && pageLimit > 0 && (!pref || !pref?.includes('solr'))) {
    query = {...query, 'page[limit]': pageLimit};
  }
  if (pageOffset && (!pref || !pref?.includes('solr'))) {
    query = {...query, 'page[offset]': pageOffset};
  }

  request(pref, false, auth)
      .get(url)
      .query(query)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => {
        if (resp.unauthorized || resp.forbidden || resp.clientError) {
          // console.error(resp.error);
          reject(resp);
          // throw new Error(resp.error);
        }
        return resp.statusCode;
      })
      .then((response) => {
        console.log('fetchAPIRequestData response 1: ---- ', url, query, opts, response);
        let new_data = (pref && pref.includes('solr')) ? applyEntityTransform(response.body, false, true) : applyEntityTransform(response.body);
        console.log('fetchAPIRequestData response 2 ALTERED : ---- ', new_data);

        resolve({
          page_data: response.statusCode === 200 ? new_data : {},
          statusCode: response.statusCode,
          ttlResults: (response.body?.meta?.count) ? parseInt(response.body.meta.count) : false,
          ttlPages: (pageLimit && pageLimit > 0 && response.body?.meta?.count) ? Math.ceil(parseInt(response.body.meta.count) / parseInt(pageLimit)) : false,
        });
      })
      .catch((error) => {
        console.error('fetchAPIRequestData. Could not fetch page data.', error);
        reject(error);
      });
});

/**
 * Returns single Menu links from Drupal backend by machine_name.
 *
 * @param id
 *   Machine name of the menu.
 *
 * @returns {Promise<any>}
 */
export const getMenuLinks = id => new Promise((resolve, reject) => {

  request('decouple')
    .get(`/menu/${id}`)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => resp.statusCode)
      .then((response) => {
        resolve({
          menu: response.statusCode === 200 ? response.body.data : {},
          statusCode: response.statusCode,
        });
      })
      .catch((error) => {
        console.error('Could not fetch the menu.', error);
        reject(error);
      });
});

/**
 * Returns metadata values from Drupal backend by path.
 *
 * @param id
 *   Path (internal or alias) to retrieve metadata for.
 *
 * @returns {Promise<any>}
 */
export const getMetatags = id => new Promise((resolve, reject) => {
  request('decouple')
    .get(`/metatag?path=/${id}`)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => resp.statusCode)
      .then((response) => {
        resolve({
          metatags: response.statusCode === 200 ? metadata(response.body.data) : {},
        });
      })
      .catch((error) => {
        console.error('Could not fetch the meta data.', error);
        reject(error);
      });
});

/**
 * Returns metadata values from Drupal backend by path.
 *
 * @param path
 *   Path (internal or alias) to retrieve blocks for.
 * @param mode
 *   Opts: data|link.
 *
 * @returns {Promise<any>}
 */
export const getPageBlocks = (path, mode) => new Promise((resolve, reject) => {
  let m = mode ? mode : 'data';
  let p = path ? ((!path || path == '') ? '/' : path) : '';

  request('decouple')
      .get(`/block?mode=${m}&path=${p}`)
      // Tell superagent to consider any valid Drupal response as successful.
      // Later we can capture error codes if needed.
      .ok(resp => resp.statusCode)
      .then((response) => {
        resolve({
          blocks: response.statusCode === 200 ? response.body.data : {},
        });
      })
      .catch((error) => {
        console.error('Could not fetch the blocks data.', error);
        reject(error);
      });
});

/**
 * Returns internal info about the page.
 *
 * @param string uuid
 *   Drupal user UUID to update.
 * @param object data
 *   Request data as JSON, e.g. {field_name1: "data", field_name2: ["data1",
 *   "data2"]}.
 * @param object opts
 *
 * @param string url
 *   Optional. URL path. Default is "/api/users/[UUID]".
 *
 * @returns {Promise<any>}
 */
export const updateUserData = async (uuid, data, opts, url) => new Promise((resolve, reject) => {
  if (!uuid || !opts.auth || !data) {
    return Promise.reject('Not enough data');
  }

  const url = url || `/users/${uuid}`;
  const pref = opts?.type || 'api';
  const auth = opts?.auth || false; // "auth" should contain oAuth access token.

  request(pref, false, auth)
    .patch(url)
    .send({
      "data": {
        "type": "users",
        "id": uuid,
        "attributes": data,
      }
    })
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => {
      if (resp.unauthorized || resp.forbidden || resp.clientError) {
        console.error(resp.error);
        // throw new Error(resp.error);
      }
      return resp.statusCode;
    })
    .then((response) => {
      resolve({
        statusCode: response.statusCode,
        updResult: response,
      });
    })
    .catch((error) => {
      console.error('updateUserData. Could not complete update request.', error);
      reject(error);
    });
});

/**
 * Returns listings search resultset from Solr.
 *
 * @param pageLimit
 *   Listings per page.
 *
 * @param pageOffset
 *   Amount of listings to skip from the beginning (for pagination).
 *
 * @returns {Promise<any>}
 */
export const drupalUserAccountActions = async (url, opts, params) => new Promise((resolve, reject) => {
  let auth = false;
  if (!url || !opts?.method) {
    return Promise.reject(new Error(`Missing required URL and/or method.`));
  }
  let def_data = {
    "data": {
      "type": "users",
      "attributes": {},
    }
  };

  if (url?.includes('user/password/reset')) {
    def_data.data.type = 'user--password-reset';
  }
  if (opts?.uuid) {
    def_data.data.id = opts.uuid;
    auth = opts.auth;
  }

  def_data.data.attributes = params ? {...def_data.data.attributes, ...params} : {};
  const pref = opts?.type || 'api';

  if (opts.method == 'delete') {
    def_data = {};
  }

  request(pref, false, auth)[opts.method](url)
    .send(def_data)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    //   .ok(resp => resp.statusCode)
    .ok((resp) => {
      if (![403, 422].includes(resp.statusCode) && (resp.statusCode > 302 || resp.statusCode < 200)) {
        throw new Error('Server error during registration');
      }
      return true;
    })
    .then((response) => {
      resolve({
        page_data: response.body?.data || [],
        errors: response.body?.errors || false,
        api_meta: response.body?.meta || {},
        statusCode: response.statusCode,
        initial: false,
      });
    })
    .catch((error) => {
      console.error('Error during USER create/update.', error);
      // return {}
      reject(error); // throws window error, not good
    });
});
