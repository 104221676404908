import React, {Fragment} from 'react';
import Script from "next/script";

const IFRAME_RESIZER = '//cdn.jsdelivr.net/gh/davidjbradshaw/iframe-resizer@4.2.10/js/iframeResizer.min.js';

const WebformEmbed = (props) => {
  // We only need this component on Client side, technically.
  if (typeof window === 'undefined') {
    return null;
  }

  let {
    webformid,
    html_id,
    name,
    jquery,
    block,
    skipData,
    frameMinHeight
  } = props;
  let data;

  if (block?.data) {
    const block_data = block?.data || '';
    data = JSON.parse(block_data);
    ({webformid} = data);
    jquery = `/scripts/fub.js`;
  }
  if (!webformid) {
    return null;
  }

  html_id = html_id || `iframe-webform-${webformid}`;
  name = name || `iframe_webform_${webformid}`;
  let url = skipData ? window.location.origin : process.env.NEXT_PUBLIC_BACKEND_URL;

  return (
    <Fragment key={`WebformEmbed-wrap`}>
      <iframe key={html_id} name={name} id={html_id}
              src={`${url}/webform/${isNaN(webformid) ? webformid : "webform_" + webformid}/share/iframe-resizer/4.2.10`}
              className="webform-share-iframe"
              allowtransparency="true"
              frameBorder={0}
              allow="geolocation; microphone; camera"
              allowFullScreen={true}
              style={{
                width: '100%',
                minWidth: '100%',
                minHeight: frameMinHeight,
              }}>
      </iframe>
      {jquery &&
      <Script key={`jquery-fub`} id={`jquery-fub`} src={`${jquery}`}
              strategy={`afterInteractive`}
              onError={() => {
                console.warn(`${jquery} load error`);
              }}
      />
      }
      <Script key="iframe-resizer" id="iframe-resizer" src={IFRAME_RESIZER}
              strategy={`afterInteractive`}
              onLoad={() => {
                setTimeout(function () {
                  iFrameResize({
                    warningTimeout: 10000,
                    checkOrigin: false,
                    heightCalculationMethod: 'lowestElement'
                  }, '.webform-share-iframe');
                }, 2000);
              }}
      />
      <Script key={`eaton-web`} id={`eaton-web`} strategy="afterInteractive"
              src={`/scripts/webforms.js`}
              onError={() => {
                console.warn('webforms.js load error');
              }}
      />
    </Fragment>
  );
};

export default WebformEmbed;
