export const listSaleStatus = {
  1: 'On Market',
  2: 'Contract Pending',
  3: 'Sold',
  4: 'Rented',
  5: 'Pocket Listing',
  6: 'Application Pending',
};

export const listSaleStatus_3 = {
  status: 3,
  label: 'Recently',
  strongLabel: 'Sold',
  statusColor: 'warning.contrastText',
  statusBgColor: 'warning.main',
  statusBanner: 0,
};

export const listSaleStatus_6 = {
  status: 6,
  label: 'Application',
  strongLabel: 'Pending',
  statusColor: 'warning.contrastText',
  statusBgColor: 'grey.300',
  statusBanner: 0,
};

export const listSaleStatus_4 = {
  status: 4,
  label: 'Leased until',
  strongLabel: 'February 28, 2022',
  statusColor: 'warning.contrastText',
  statusBgColor: 'grey.300',
  statusBanner: 0,
};

export const listSaleStatus_2 = {
  status: 2,
  label: 'Under',
  strongLabel: 'Contract',
  statusColor: 'success.contrastText',
  statusBgColor: 'success.main',
  statusBanner: 0,
};

export const listSaleStatus_1 = {
  status: 1,
  label: 'Confirmed available on',
  strongLabel: 'October 15, 2021',
  statusColor: 'success.contrastText',
  statusBgColor: 'warning.main',
  statusBanner: 0,
};

export const listOpenHouseStatus = {
  status: 1,
  label: 'OPEN HOUSE ',
  strongLabel: 'October 15, 2021',
  statusColor: 'success.contrastText',
  statusBgColor: 'warning.main',
  statusBanner: 0,
};

export const listImages = [{
  src: "https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80",
  imgAlt: "Main",
  imgTitle: "Main",
},
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgAlt: "Test",
    imgTitle: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
  {
    src: "https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80",
    imgTitle: "Test",
    imgAlt: "Test",
  },
];


export const Listing1 = {
  id: '11123233-2342342-23234-23424353',
  url: '/listings/11123233-2342342-23234-23424353',
  beds: 3,
  price: '$750,000',
  bathAll: '1',
  sqft: '4,252 SF.',
  address: '48123 Another Road Lithia, FL 33467',
  daysListed: 3,
  status: 'sold',
  label: 'Recently',
  strongLabel: 'Sold',
  statusColor: 'warning.contrastText',
  statusBgColor: 'warning.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1599809275671-b5942cabc7a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80',
  alias: '/listing/buy/8237648276',
  internal: '/node/45',
  internalId: 45,
  nid: 45,
  statusBanner: 1,
  bathFull: 0,
  bathHalf: 0,
  body: "<p>This beautiful 2 story, 2 bedrooms, 2.5 bathroom townhouse is&nbsp;available to rent&nbsp;in the gated FishHawk Ridge enclave of the much desired FishHawk Ranch community.&nbsp;Enjoy full access to all FishHawk's award-winning amenities including tennis courts, pools, fitness centers, 30+ miles of walking trails and \"A\" rated schools.</p>\r\n\r\n<p>The home features stunning wood tile throughout the entire first floor, a formal dining room with custom lighting, a downstairs half bath, ceiling fan in the living room, and plush carpeting up the stairs and in the bedrooms.&nbsp;</p>\r\n\r\n<p>Overlooking the living room with lanai access and conservation views, the bright kitchen boasts crisp cabinetry, granite countertops, subway tiled backsplash, and a breakfast bar to enjoy casual meals.&nbsp; Appliances include a sleek black refrigerator, range, microwave, and dishwasher.</p>\r\n\r\n<p>Up the stairs, you'll find a laundry closet&nbsp;and the 2 spacious bedrooms.&nbsp; Both bedrooms have dual closets for extra storage and en-suite bathrooms with wood tile flooring and granite countertops.</p>\r\n\r\n<p>Complete lawn maintenance, including mowing, shrub pruning, irrigation system service, turf, and plant fertilization and plant pest control are included in rent services saving you time and money!</p>\r\n\r\n<p>Enjoy use of the neighborhood pool, exclusive to FishHawk Ridge residents!&nbsp;</p>\r\n",
  eaton: 1,
  city: "Tampa",
  dateAvail: "2021-12-08",
  fireplaceYn: "No",
  garage: 0,
  lotSize: 0,
  saleStatus: 4,
  mlsNum: "",
  petsPolicy: "",
  commPoolYn: "No",
  poolYn: "No",
  mlsSubtype: "Townhouse",
  saleType: "Rental",
  slides: listImages,
  state: "FL",
  street: "4120 N NEBRASKA AVE",
  title: "4120 N NEBRASKA AVE, Tampa 33603",
  waterfrontYn: "No",
  yearBuilt: 2005,
  zip: "33603",
  extFeat: ["Fence", "French Doors", "Lighting"],
  intFeat: ["Ceiling Fans(s)", "Eat-in Kitchen", "Kitchen\/Family Room Combo", "Living Room\/Dining Room Combo", "Stone Counters", "Walk-In Closet(s)"],
  virtual: "http://some-virtual-tour.com",
  office: "54 Realty Llc",
  fieldDaysOnMarket: 23,
  lat: 27.983125,
  lon: -82.45131
};

export const Listing2 = {
  id: '984958-2342342-23234-23424353',
  url: '/listings/984958-2342342-23234-23424353',
  beds: 2,
  price: '$170,000',
  bathAll: '1',
  sqft: '2,200 SF.',
  address: '123 Fake Street Lithia, FL 33467',
  daysListed: 3,
  status: 'sold',
  label: 'Under',
  strongLabel: 'Contract',
  statusColor: 'success.contrastText',
  statusBgColor: 'success.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '/listing/rent/232423',
  internal: '/node/23',
  nid: 23,
  internalId: 23,
  statusBanner: 1,
};

export const Listing3 = {
  id: '774875-2342342-23234-23424353',
  url: '/listings/774875-2342342-23234-23424353',
  beds: 5,
  price: '$490,000',
  bathAll: null,
  sqft: '3,200 SF.',
  address: '999 Fake Street Tampa, FL 33467',
  daysListed: 3,
  status: 'sold',
  label: 'For',
  strongLabel: 'Sale',
  statusColor: 'primary.contrastText',
  statusBgColor: 'primary.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1566908829550-e6551b00979b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2849&q=80',
  alias: '',
  internal: '/node/11',
  nid: 11,
  internalId: 11,
  statusBanner: 1,
};

export const Listing4 = {
  id: '234234-644564-23234-23424353',
  url: '/listings/234234-644564-23234-23424353',
  beds: 2,
  price: '$660,000',
  bathAll: '2',
  sqft: '1,900 SF.',
  address: '66 No Image Street Tampa, FL 33467',
  daysListed: null,
  status: 'sold',
  label: 'For',
  strongLabel: 'Sale',
  statusColor: 'primary.contrastText',
  statusBgColor: 'primary.main',
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://images.unsplash.com/photo-1613490493576-7fde63acd811?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80',
  alias: '',
  internal: '/node/78',
  nid: 78,
  internalId: 78,
  statusBanner: 1,
};

export const AgentSlide1 = {
  id: '234234-644564-23255-23424353',
  internalId: 11,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://cms.eatonrealty.com/sites/default/files/styles/max_510_height/public/images/images/agent/2021/05/jrobinsonws.jpg',
  title: 'SANDRA BOCKELMANN',
  subtitle: 'DIRECTOR OF PROPERTY MGMT., MPM',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com"
};

export const AgentSlide2 = {
  id: '234234-644564-11234-23424353',
  internalId: 22,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://cms.eatonrealty.com/sites/default/files/styles/max_510_height/public/images/images/agent/2021/05/jrobinsonws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com"
};

export const AgentSlide3 = {
  id: '234234-644564-99090-23424353',
  internalId: 23,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://cms.eatonrealty.com/sites/default/files/styles/max_510_height/public/images/images/agent/2021/05/jrobinsonws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com"
};

export const AgentSlide4 = {
  id: '234234-644564-23234-23222353',
  internalId: 211,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://cms.eatonrealty.com/sites/default/files/styles/max_510_height/public/images/images/agent/2021/05/jrobinsonws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com"
};

export const AgentSlide5 = {
  id: '234234-644564-23234-23433353',
  internalId: 223,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.eatonrealty.com/sites/default/files/agents-images/hdietzws2.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com"
};

export const AgentSlide6 = {
  id: '234234-644564-23234-11424353',
  internalId: 44,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://www.eatonrealty.com/sites/default/files/agents-images/jrodriguezws.jpg',
  title: 'John Smith',
  subtitle: 'REALTOR | ABC',
  body: 'Your landlord resource, Bill oversees onboarding new homes and is the main point of contact for all things owner related.',
  alias: "https://www.eatonrealty.com",
  email: 'shawn@eatonrealty.com',
  address: [{address_phonenumber: '(813)-672-8022'}],
  phoneExt: '115'
};

export const AgentListing1 = {
  id: '234234-644564-23255-23424353',
  internalId: 11,
  imgAlt: "Alt text",
  imgTitle: "Title text",
  imageOrig: 'https://cms.eatonrealty.com/sites/default/files/images/images/agent/2021/05/stibbsws.jpg',
  title: 'SUSIE TIBBS',
  subtitle: 'REALTOR',
  summary: '<p>Susie joined the Eaton team after completing a long and successful career in sales management, marketing, and the training of management personnel.</p><p>She grew up in West Virginia where she attended Bluefield State College before moving to the Washington D.C. area. Her career took her to New York and then to Virginia Beach, Virginia, where she met her husband, a Navy pilot. They are blessed with one amazing son, and have two lovable dogs. Between her corporate career and her husband’s Navy career, she has called many places home, but since 2008, she has come to truly love the active, scenic, and fun lifestyle Florida has to offer.</p><p>Susie combines knowledge of marketing, strong attention to detail; project management, negotiation skills, and business savvy with her ability and passion to help clients achieve their goals. These skills are not stand alone, but are greatly enhanced when blended with her many personal experiences as a homebuyer of existing and new construction homes, a seller, a landlord and tenant.</p><p>Whether you are relocating or just looking for a new place to call home, Susie is ready and eager to assist you with all your real estate needs!</p><p>&nbsp;</p>',
  body: '<p>Susie joined the Eaton team after completing a long and successful career in sales management, marketing, and the training of management personnel.</p><p>She grew up in West Virginia where she attended Bluefield State College before moving to the Washington D.C. area. Her career took her to New York and then to Virginia Beach, Virginia, where she met her husband, a Navy pilot. They are blessed with one amazing son, and have two lovable dogs. Between her corporate career and her husband’s Navy career, she has called many places home, but since 2008, she has come to truly love the active, scenic, and fun lifestyle Florida has to offer.</p><p>Susie combines knowledge of marketing, strong attention to detail; project management, negotiation skills, and business savvy with her ability and passion to help clients achieve their goals. These skills are not stand alone, but are greatly enhanced when blended with her many personal experiences as a homebuyer of existing and new construction homes, a seller, a landlord and tenant.</p><p>Whether you are relocating or just looking for a new place to call home, Susie is ready and eager to assist you with all your real estate needs!</p><p>&nbsp;</p>',
  alias: "https://www.eatonrealty.com",
  publicEmail: "stibbs@eatonrealty.com",
  email: "stibbs@eatonrealty.com",
  social: [
    {first: 'Zillow', second: "https://www.zillow.com/profile/Susie%20Tibbs"},
    {
      first: 'Facebook',
      second: "https://www.facebook.com/Susie-Tibbs-Realtor-100689788364833/"
    },
    {
      first: 'Linkedin',
      second: "https://www.linkedin.com/in/susie-tibbs-566835149"
    },
    {first: 'Twitter', second: "https://twitter.com/eatonrealty?lang=en"},
  ],
  zillowReview: 'https://www.zillow.com/reviews/write/?s=X1-ZUwcs0bac9pp8p_1d5w8',
  address: [
    {
      "country_code": "US",
      "administrative_area": "",
      "locality": "",
      "dependent_locality": null,
      "postal_code": "",
      "sorting_code": null,
      "address_line1": "N/A",
      "address_line2": "",
      "organization": "",
      "given_name": "",
      "additional_name": null,
      "family_name": "",
      "address_phonenumber": "(813) 431-2701",
      "address_faxnumber": ""
    }
  ],
  boundaryRef: [
    {
      id: '11123233-2342342-4444444-23424353',
      alias: '/city/appolo-beach',
      internal: '/taxonomy/term/11',
      internalId: 11,
      // imgAlt: "Alt text",
      // imgTitle: "Title text",
      imageOrig: 'https://www.eatonrealty.com/sites/default/files/styles/large/public/gallery_city/apollobeachboating.jpg?itok=2kdbN-Je',
      title: 'Apollo Beach',
      body: '<p>Known for being a carnival town, Gibsonton\n' +
        '                  is full of character. GibTown, as it is known among locals,\n' +
        '                  has become the famous wintering town of many sideshow\n' +
        '                  performers and is home to the International Independent\n' +
        '                  Showmen\'s Museum. </p>',
      slides: [
        {
          src: "https://www.eatonrealty.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
          imgAlt: "Main",
          imgTitle: "Main",
        },
        {
          src: "https://www.eatonrealty.com/sites/default/files/styles/slider/public/gallery_city/water_sports_in_gibsonton.png?itok=RcEeYExr",
          imgAlt: "Test",
          imgTitle: "Test",
        },
        {
          src: "https://www.eatonrealty.com/sites/default/files/styles/slider/public/gallery_city/gibsonton_showmens_museum.png?itok=0px58TgY",
          imgAlt: "Test",
          imgTitle: "Test",
        },
      ]
    },
    {
      id: '11123233-2342342-4444444-23424353',
      alias: '/city/dover',
      internal: '/taxonomy/term/14',
      internalId: 14,
      imgAlt: "Alt text",
      imgTitle: "Title text",
      imageOrig: 'https://www.eatonrealty.com/sites/default/files/styles/large/public/gallery_city/sydney_dover_park.jpg?itok=DAbPR5Fn',
      title: 'DOVER',
      body: 'Dover is a small unincorporated town located east of Tampa Bay and in recent years has experienced significant growth as residents continue to spread outside of the city.',
      slides: [{
        src: "https://www.eatonrealty.com/sites/default/files/styles/slider/public/gallery_city/bullfrog_creek.png?itok=o6YHkKfE",
        imgAlt: "Main",
        imgTitle: "Main",
      },
      ]
    }
  ]
};
