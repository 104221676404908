import {useEffect, useReducer} from 'react';
import Script from "next/script";
import App from 'next/app';
import {ThemeProvider} from '@material-ui/core/styles';
import {themeExtender} from '../.storybook/theme.js';
import {CookiesProvider} from "react-cookie";

import ErrorBoundary from "../components/01_atoms/ErrorMessage/ErrorBoundary";
import HtmlHead from '../components/01_atoms/HtmlHead';
import ErrorMessage from '../components/01_atoms/ErrorMessage';
import SiteLayout from '../components/04_templates/PageLayouts/GlobalLayout';
import '../components/01_atoms/PageProgressBar';

import CurrentPageContext from "../components/context/CurrentPageData";
import * as globalAPI from '../api/global';
import * as transforms from "../utils/transforms";
import '../styles/globals.scss';
import {GTM_ID} from '../public/scripts/gtm';

const axios = require('axios');

let curr_page = {};

MyApp.getInitialProps = async (appContext) => {
  const {Component, ctx} = appContext;

  const initialProps = {
    page: {},
    menu: {},
    footermenu: {},
    metatags: [],
    blocks: {},
    isServer: !!ctx.req,
    statusCode: 200
  };

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  curr_page = appProps?.pageProps?.page;
  const regex1 = new RegExp('\/_next\/data\/[a-zA-Z0-9_\&\$\-]+\/(.*)', 'i');

  try {
    let assemble = null;
    if (appProps?.pageProps?.page?.entity && ctx?.pathname?.includes('[uuid]')) {
      assemble = globalAPI.getGlobalElements(appProps.pageProps.page.entity);
    }
    else if (initialProps.isServer && ctx?.pathname?.includes('error') !== true) {
      let byPath = ctx.req?.url?.includes('_next') ? ctx.asPath : ctx.req.url;
      let result = byPath.match(/\/_next\/data\/[a-zA-Z0-9]+\/(.*)/i);

      if (byPath && byPath.includes('_next/data') && result?.[1]) {
        byPath = `/${result?.[1]}`;
      }
      if (byPath && !byPath.includes('[')) {
        // At least identify article categories or internal paths for terms. So
        // that route resolving by path is constructed properly next.
        let ent_type = ((ctx?.pathname?.includes('[category]') || ctx?.query?.category || ctx?.query?.tid)
          && !ctx.query?.blog && !ctx.query?.node && !ctx.query?.nid) ? 'term' : false;
        assemble = globalAPI.getGlobalElementsLookup(byPath, ent_type);
      }
    }

    if (assemble) {
      const sub = await axios
          .get(assemble.getUrl(), {"Accept": "multipart/related"})
          .catch(err => {});

      if (sub?.data !== undefined/* && typeof sub.data === 'object' */) {
        Object.keys(sub.data).map(i => {
          let json = null;

          if (sub.data[i].body?.includes('{')) {
            json = JSON.parse(sub.data[i].body);
          }
          if (json && i.includes('pagemeta')) {
            initialProps.metatags = transforms.metadata(json.data);
          }
          else if (json && i.includes('pagemenu')) {
            initialProps.menu = json.data;
          }
          else if (json && i.includes('footermenu')) {
            initialProps.footermenu = json.data;
          }
          else if (json && i.includes('pageblocks')) {
            initialProps.blocks = json.data;
          }
          else if (json && i.includes('pageroute')) {
            initialProps.page = json;
          }
          else if (!json) {
            console.error("Could not parse response as JSON in " + i + " request.");
          }
        });
      }
    }
  }
  catch (e) {
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    console.error("EatonRealty: 500 error", e);
    initialProps.statusCode = 500;

    // In case of Server Side rendering we want the server to throw the
    // correct error code.
    if (ctx) {
      ctx.statusCode = 500;
    }
  }

  appProps.pageProps = {
    ...initialProps,
    ...appProps.pageProps
  };

  curr_page = {
    ...curr_page,
    ...initialProps,
    ...appProps
  };

  return appProps;
};

function authUserReducer(state, item) {
  return {...state, ...item};
}

function MyApp({Component, pageProps}) {
  useEffect(() => {
    if (document.location.href.indexOf('buy/homes-for-sale') <= -1 && document.location.href.indexOf('rent/homes-for-rent') <= -1) {
      document.body.classList.add('not-search-body');
    }
  })

  // https://dev.to/felixmohr/setting-up-a-blog-with-next-js-react-material-ui-and-typescript-2m6k
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const [authUserCtx, setAuthUserCtx] = useReducer(authUserReducer, {});
  const {menu, metatags, footermenu, blocks} = pageProps;

  // Different container wrapper around certain pages:
  const pageLayout = '';
  const statusCode = pageProps.statusCode || 200;
  const meta = (metatags && metatags.tags) || [];

  return (
    <>
      <Script strategy="afterInteractive" className={`gtm-tag-js`}
              id={"gtm-script"} async={`async`}
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=(w[l]||[]);w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',"${GTM_ID}");`,
              }}
      />
      <ThemeProvider theme={themeExtender}>
        <CookiesProvider>
          <HtmlHead metatags={meta}/>
          <CurrentPageContext.Provider value={{authUserCtx, setAuthUserCtx}}>
            <SiteLayout menu={menu} footermenu={footermenu} blocks={blocks}
                        layout={pageLayout}>
              {statusCode === 200
              && <ErrorBoundary><Component {...pageProps} /></ErrorBoundary>
              }
              {statusCode !== 200
              && <ErrorMessage statusCode={statusCode}/>
              }
            </SiteLayout>
          </CurrentPageContext.Provider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  );
}

// export default withRedux(configureStore)(withReduxSaga(MyApp));
export default MyApp;
