import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Link from 'next/link';
import Image from 'next/image';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  wrapper: {
    marginRight: 'auto',
    backgroundColor: props => props.reverseLogo
      ? '#555'
      : 'transparent',
    "& .logo": {
      [`@media (max-width: 768px)`]: {
        width: '250px !important',
        maxWidth: '100% !important',
      },
      maxWidth: '100% !important',
      width: '315px !important',
    },
    "& img": {
      height: '56px !important',
      width: '254px !important',
      [`@media (min-width: 600px)`]: {
      height: '70px !important',
      width: '315px !important',
    },
    },
  },
});

export default function Logo(props) {
  let {
    logoPriority
  } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.wrapper}>
      <Link href="/">
        <a className="logo">
          <Box style={{width: 315, height: 63, display: 'inline'}}>
    {  logoPriority &&
          <Image priority layout="intrinsic"
          width="315" height="63"
          src={props.name}
          alt={props.alt}
          />
        }
        {!  logoPriority &&

          <Image layout="intrinsic"
          width="315" height="63"
          src={props.name}
          alt={props.alt || 'Logo'}
          />
        }
            </Box>
        </a>
      </Link>
    </div>
  );
}
