import {Fragment, useEffect} from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {pageview} from "../../../public/scripts/gtm";

const HtmlHead = ({metatags}) => {
  // @see:
  // https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    }
  }, [router.events]);

  return (
    <Fragment key="head-fragment">
      <Head key="head">
        <meta charSet="utf-8" key="utf"/>
        <meta name="viewport"
              content="width=device-width, minimum-scale=1, initial-scale=1"
              key="viewport"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" key="xua"/>
        <link rel="shortcut icon" href="/favicon.ico"
              type="image/vnd.microsoft.icon" key="shortcut"/>
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
              key="style1"/>
      </Head>
      {metatags.map((tag, i) => {
        let type = tag[0]['#tag'];
        return (
          <Head key={`head${i}`}>
            {type == 'link' && <link rel={tag[0]['#attributes'].rel}
                                     href={tag[0]['#attributes'].href}
                                     key={tag[1]}/>}
            {type == 'meta' && tag[1] == 'title' &&
            <title key="title">{tag[0]['#attributes'].content}</title>}
            {type == 'meta' && tag[1] != 'title' &&
            <meta name={tag[0]['#attributes']?.property || tag[1]}
                  content={tag[0]['#attributes'].content}
                  key={tag[1]}/>}
          </Head>
        );
      })}
    </Fragment>
  );
};

HtmlHead.propTypes = {
  metatags: PropTypes.arrayOf(PropTypes.array),
};
HtmlHead.defaultProps = {
  metatags: [],
};

export default HtmlHead;
