var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Y2zwfKZXd6K1PVwPcr4Hj"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
const config_platformsh = require("platformsh-config").config();
const config_vars = config_platformsh.variables();

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || config_vars.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN || config_vars.SENTRY_AUTH_TOKEN || '';

Sentry.init({
  dsn: SENTRY_DSN || 'https://c69b006791754a8eb24756da951027eb@o1339468.ingest.sentry.io/6617081',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: .2,
  environment: process.env.NODE_ENV || 'preview',
  integrations: [
    new CaptureConsole({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error']
    })
  ],
});
