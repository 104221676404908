import * as listingHelpers from '../helpers/listing';

/**
 * Pick transformation resource to apply.
 */
export const applyEntityTransform = (values, rsc, solr) => {
  if (!values) {
    console.warn("applyEntityTransform: NO DATA LOADED");
    return values;
  }
  let data, new_data = [], restapi, resAsArray, new_val;

  restapi = values.data ? false : true;
  resAsArray = (values instanceof Array || values.data instanceof Array) ? true : false;

  data = values.data || values;
  if (!(data instanceof Array)) {
    data = [data];
  }

  data.map((res) => {
    new_val = res;
    if (solr) {
      if (rsc?.includes('listing') || res.its_price || res.ss_mls_num) {
        new_val = listingSolr(res);
      }
      else {
        console.warn('NO TRANSFORMATION LOGIC FOUND FOR CONTENT FROM SOLR');
      }
    }
    else if (restapi && !rsc) {
      if (!res.isUser && (res.name || res.isTerm)) {
        new_val = taxonomyDrupalTransform(res);
      }
      else if ((res.username || res.isUser)) {
        new_val = userDrupalTransform(res);
      }
      else if (res.type == 'listings') {
        new_val = listingDrupalTransform(res);
      }
      else {
        new_val = nodeDrupalTransform(res);
      }
    }
    else {
      if ((rsc?.includes('listing')) || res.type == 'listings') {
        new_val = listingDrupalTransform(res);
      }
      else if ((rsc?.includes('taxonomy')) || (res.relationships?.vocab)) {
        new_val = taxonomyDrupalTransform(res);
      }
      else if ((rsc?.includes('user')) || res.type == 'users' || res.isUser) {
        new_val = userDrupalTransform(res);
      }
      else {
        new_val = nodeDrupalTransform(res);
      }
    }

    new_data.push(new_val);
  });

  return resAsArray ? new_data : new_data[0];
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const nodeDrupalTransform = (data) => {
  if (!data || !data?.internalId) {
    console.warn("TRANSFORM RANDOM NODE DATA: NOTHING TO TRANSFORM");
    return data;
  }

  const nodeprops = data.attributes ? data.attributes : data;
  var new_data = data;
  delete new_data['attributes'];
  delete new_data['relationships'];

  var internal = nodeprops.internalId ? listingHelpers.internalURL(nodeprops.internalId) : '';

  new_data = {
    ...new_data,
    id: data.id,
    nid: nodeprops.internalId || data.internalId || null,
    alias: data.rest_alias || data.path?.alias || nodeprops.path?.alias || internal || '',
    title: data.title || '',
    url: listingHelpers.url(data.type, data.id),
    internal: internal,
    summary: (data.summary?.processed || data.summary || ''),
    image_styles: data.image?.imageFile?.links || [],
    imageOrig: data?.imageOrig || null,
    slides: data?.slides || [],
  };
  console.warn("TRANSFORM RANDOM NODE DATA", new_data, data);

  if (data.mlsSubtype) {
    new_data = {
      ...new_data,
      mlsSubtype: (Array.isArray(data.mlsSubtype) && data.mlsSubtype) || [data.mlsSubtype],
    };
  }

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }

  if (body_val && body_val.includes(`"json"`)) {
    new_data = {...new_data, body_json: JSON.parse(body_val) || {}};
  }
  else {
    new_data = {...new_data, body: body_val || ''};
  }

  if (data?.image?.imageFile?.uri?.url) {
    const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : 'https://cms.eatonrealty.com';

    new_data = {
      ...new_data,
      imageOrig: env + (data.image.imageFile.uri.url) || '',
      imgTitle: data.image?.relationships?.imageFile?.data?.meta?.title || data.title || '',
      imgAlt: data.image?.relationships?.imageFile?.data?.meta?.alt || data.title || '',
      imgHeight: data.image?.relationships?.imageFile?.data?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.data?.meta?.width || 0,
    };
  }

  if (data.slides?.length > 0) {
    let slides = [];
    if (data.slides && data.slides.length > 0 && data.slides[0].src !== undefined) {
      slides = data.slides;
    }
    else {
      slides = composeSliderMedia(data.slides);
    }
    new_data = {...new_data, slides: slides || []};

    if (!data?.image?.imageFile && slides?.length > 0) {
      new_data = {
        ...new_data, ...slides[0],
        imageOrig: slides[0].src || '',
      };
    }
  }

  if (data.category) {
    new_data = {...new_data, categoryTerm: applyEntityTransform(data.category)};
  }

  new_data.cityTerm = null;
  if (data.cityTerm) {
    new_data = {...new_data, cityTerm: [applyEntityTransform(data.cityTerm)]};
    if (data.type == 'boundaryPages') {
      new_data = {
        ...new_data,
        boundaryTermName: ((new_data.cityTerm && new_data.cityTerm[0]?.name) || data.title)
      };
    }
  }
  new_data.neighborTerm = null;
  if (data.neighborTerm) {
    new_data = {
      ...new_data,
      neighborTerm: applyEntityTransform(data.neighborTerm)
    };
    if (data.type == 'boundaryPages') {
      new_data = {
        ...new_data,
        boundaryTermName: (new_data.neighborTerm?.name || data.title)
      };
    }
  }
  new_data.commTerm = null;
  if (data.commTerm) {
    new_data = {...new_data, commTerm: applyEntityTransform(data.commTerm)};
    if (data.type == 'boundaryPages') {
      new_data = {
        ...new_data,
        boundaryTermName: (new_data.commTerm?.name || data.title)
      };
    }
  }
  new_data.schoolTerm = null;
  if (data.schoolTerm) {
    new_data = {...new_data, schoolTerm: applyEntityTransform(data.schoolTerm)};
    if (data.type == 'boundaryPages') {
      new_data = {
        ...new_data,
        boundaryTermName: (new_data.schoolTerm?.name || data.title)
      };
    }
  }
  new_data.specialLoc = null;
  if (data.specialLoc) {
    new_data = {...new_data, specialLoc: applyEntityTransform(data.specialLoc)};
    if (data.type == 'boundaryPages') {
      new_data = {
        ...new_data,
        boundaryTermName: (new_data.specialLoc?.name || data.title)
      };
    }
  }
  new_data.listingRef = null;
  if (data.listingRef) {
    let redirect = data.listingRef?.path?.alias ? data.listingRef.path.alias : `/node/${data.listingRef.internalId}`;
    new_data = {...new_data, listingRef: redirect};
  }

  return new_data;
};

/**
 * Transforms data from Solr into something more readable on the frontend.
 */
export const listingSolr = (data) => {
  if (!data) {
    console.warn("TRANSFORM LISTING SOLR DATA: NO DATA LOADED");
    return data;
  }

  let shortAddr = '';
  let address_str = data.tm_X3b_en_addr1 ? data.tm_X3b_en_addr1[0] : null;
  address_str = (address_str && data.tm_X3b_en_addr2) ? address_str + ', ' + data.tm_X3b_en_addr2[0] : address_str;
  address_str = (address_str && data.tm_X3b_en_city) ? address_str + ', ' + data.tm_X3b_en_city[0] : address_str;
  shortAddr = address_str;
  address_str = (address_str && data.tm_X3b_en_state) ? address_str + ', ' + data.tm_X3b_en_state[0] : address_str;
  address_str = (address_str && data.ss_zip) ? address_str + ' ' + data.ss_zip : address_str;

  let return_data = {
    id: data.ss_uuid,
    internalId: data.its_nid?.toString() || null,
    nid: data.its_nid || null,
    mlsSubtype: data.sm_prop_type || null,
    alias: data.ss_url && !data.ss_url.includes('node/') ? data.ss_url : '',
    title: (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || '',
    shortAddr: shortAddr,
    address: address_str || (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || '',
    // ohDates: (data.ds_oh_coming && data.ds_oh_coming_end) ? [{first: data.ds_oh_coming, second: data.ds_oh_coming_end, third: true}] : (data.ds_oh_coming || null),
    ohDates: data.ds_oh_coming || null,
    ohDatesEnd: data.ds_oh_coming_end || null,
    url: data.ss_uuid ? listingHelpers.url('listings', data.ss_uuid) : '',
    internal: data.its_nid ? listingHelpers.internalURL(data.its_nid) : '',
    beds: data.its_bed || 0,
    bathAll: data.its_bath_all || 0,
    bathFull: data.its_bath_full || 0,
    bathHalf: data.its_bath_half || 0,
    dateAvail: (data.ds_date_avail && data.ds_date_avail.substr(0, data.ds_date_avail.indexOf('T'))) || '-',
    sqft: data.its_sqft || 0,
    lotSize: data.fts_lot_acres || 0,
    price: data.its_price || 0,
    yearBuilt: data.its_year || 0,
    garage: data.its_garage || 0,
    eaton: (data?.its_eaton == 1) ? 1 : 0,
    broker: (data?.its_brokerage == 1) ? 1 : 0,
    mlsNum: data.ss_mls_num || '',
    imageOrig: data.ss_img_uri ? encodeURI(data.ss_img_uri.replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/')) : null,
    saleType: data.ss_sale_type || '',
    saleStatus: data.its_sale_status || false,
    office: data.ss_mls_office || null,
    daysMarket: data.its_market_days || null,
    lat: data.fts_lat,
    lon: data.fts_lon,
  };

  return return_data;
};

/**
 * Transforms data from Solr into something more readable on the frontend.
 */
export const contentNodesSolr = (data) => {
  if (!data) {
    console.warn("TRANSFORM NODES SOLR DATA: NO DATA LOADED");
    return data;
  }

  let return_data = {
    id: data.ss_uuid,
    internalId: data.its_nid?.toString() || null,
    nid: data.its_nid || null,
    contentType: data.ss_content_type || undefined,
    alias: data.ss_url && !data.ss_url.includes('node/') ? data.ss_url : '',
    internal: data.its_nid ? listingHelpers.internalURL(data.its_nid) : '',
    summary: (data.summary?.processed || data.summary || ''),
    title: (data.tm_X3b_en_title && data.tm_X3b_en_title[0]) || '',
    imageOrig: data?.imageOrig || null,
    slides: data?.slides || [],
  };

  if (return_data.contentType) {
    if (return_data.contentType == 'boundary_page') {
      return_data = {
        ...return_data,
        broker: (data?.its_brokerage == 1) ? 1 : 0,
        eaton: (data?.its_eaton == 1) ? 1 : 0,
        url: data.ss_uuid ? listingHelpers.url('boundaryPages', data.ss_uuid) : '',
        boundaryType: data.ss_boundary_type || '',
        saleType: data.ss_sale_type || ''
      };
      if (data.tm_X3b_en_neighbor_name?.length > 0) {
        return_data = {
          ...return_data,
          boundaryTermName: data.tm_X3b_en_neighbor_name[0]
        };
      }
      else if (data.tm_X3b_en_comm_name?.length > 0) {
        return_data = {
          ...return_data,
          boundaryTermName: data.tm_X3b_en_comm_name[0]
        };
      }
      else if (data.tm_X3b_en_city_name?.length > 0) {
        return_data = {
          ...return_data,
          boundaryTermName: data.tm_X3b_en_city_name[0]
        };
      }
      else if (data.tm_X3b_en_school_name?.length > 0) {
        return_data = {
          ...return_data,
          boundaryTermName: data.tm_X3b_en_school_name[0]
        };
      }
      else if (data.tm_X3b_en_special_name?.length > 0) {
        return_data = {
          ...return_data,
          boundaryTermName: data.tm_X3b_en_special_name[0]
        };
      }
    }

    let body_val = (data.body?.processed) ? data.body.processed : false;
    if (!body_val && data.body?.value) {
      body_val = data.body.value;
    }
    else if (!body_val && data.body) {
      body_val = data.body;
    }
    else if (data.tm_X3b_en_body?.length) {
      body_val = data.tm_X3b_en_body[0];
    }
    return_data = {...return_data, body: (body_val || '')};

    if (data.tm_X3b_en_summary?.length > 0) {
      return_data = {...return_data, summary: data.tm_X3b_en_summary[0]};
    }

    if (data.sm_slides_uri?.length > 0) {
      return_data = {
        ...return_data,
        imageOrig: data.sm_slides_uri[0].replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/')
      };

      let slides = [];
      data.sm_slides_uri.map((s) => {
        slides.push(s.replace("public://", process.env.NEXT_PUBLIC_BACKEND_URL + '/sites/default/files/'))
      });
      return_data = {...return_data, slides: slides};
    }
  }

  return return_data;
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const listingDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM LISTING DATA: NO DATA LOADED");
    return data;
  }

  const listprops = data.attributes ? data.attributes : data;
  let address_str = null, address_str2 = null, street = '', city = '', zip = '', addr_state = '',
    shortAddr = '';
  let address_str_unparsed = data.addr_unparsed?.replace(/([A-Za-z0-9]),([A-Za-z0-9])/gi, "$1, $2") || null;

  address_str = data?.address_line1 || data.address?.address_line1 || null;
  address_str2 = data?.address_line2 || data.address?.address_line2 || null;
  address_str = (address_str && address_str2) ? address_str + ', ' + address_str2 : address_str;
  street = address_str || '';
  city = data?.locality || data.address?.locality || '';
  address_str = (address_str && city) ? address_str + '  ' + city : address_str;
  shortAddr = address_str;
  addr_state = data?.administrative_area || data.address?.administrative_area || '';
  address_str = (address_str && addr_state) ? address_str + ', ' + addr_state : address_str;
  zip = data?.postal_code || data.address?.postal_code || '';
  address_str = (address_str && zip) ? address_str + ' ' + zip : address_str;


  var internal = listprops.internalId ? listingHelpers.internalURL(listprops.internalId) : '';
  const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : 'https://cms.eatonrealty.com';

  var new_data = data;
  delete new_data['attributes'];
  delete new_data['relationships'];

  var new_data = {
    ...new_data,
    id: data.id,
    nid: listprops.internalId || data.internalId || null,
    mlsSubtype: data.mlsSubtype || null,
    alias: data.rest_alias || data.path?.alias || listprops.path?.alias || internal || '',
    title: data.title || '',
    shortAddr: shortAddr,
    address: address_str || data.title || address_str_unparsed || '',
    street: street,
    city: city,
    state: addr_state,
    zip: zip,
    url: listingHelpers.url(data.type, data.id),
    internal: internal,
    beds: data.beds || 0,
    dateAvail: data.dateAvail || '-',
    sqft: (data.sqft && parseInt(data.sqft)) || 0,
    lotSize: (data.lotSize && parseFloat(data.lotSize)) || 0,
    price: (data.price && parseInt(data.price)) || 0,
    yearBuilt: data.yearBuilt || 0,
    garage: data.garageSpace || listprops.garage_space || 0,
    broker: (data.broker && data.broker == 1) ? 1 : 0,
    eaton: (data.eaton && data.eaton == 1) ? 1 : 0,
    waterfrontYn: data.waterfrontYn ? 'Yes' : 'No',
    fireplaceYn: data.fireplaceYn ? 'Yes' : 'No',
    poolYn: (data.poolYn) ? 'Yes' : 'No',
    commPoolYn: (data.commPoolYn) ? 'Yes' : 'No',
    petsPolicy: data.petsPolicy || '',
    mlsNum: data.mlsNum || '',
    matterport: data.matterport || null,
    imageOrig: data.imageOrig || null,
    slides: data.slides || [],
    image_styles: data.image?.imageFile?.links || [],
    saleType: data.saleType || '',
    extFeat: data.extFeat || [],
    intFeat: data.intFeat || [],
    virtual: data.virtual || null,
    office: data.office || null,
    daysMarket: data.daysMarket || null,
    lat: data.coord ? data.coord.lat : '',
    lon: data.coord ? data.coord.lon : '',
  };

  if (data?.image?.imageFile?.uri?.url) {
    new_data = {
      ...new_data,
      imageOrig: env + (data.image.imageFile.uri.url) || '',
      imgTitle: data.image?.relationships?.imageFile?.data?.meta?.title || data.title || '',
      imgAlt: data.image?.relationships?.imageFile?.data?.meta?.alt || data.title || '',
      imgHeight: data.image?.relationships?.imageFile?.data?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.data?.meta?.width || 0,
    };
  }

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }
  new_data = {...new_data, body: (body_val || '')};

  if (data.slides?.length > 0) {
    let slides = [];
    if (data.slides && data.slides.length > 0 && data.slides[0].src !== undefined) {
      slides = data.slides;
    }
    else {
      slides = composeSliderMedia(data.slides);
    }
    new_data = {...new_data, slides: slides};
  }

  if (data.cityTerm) {
    new_data = {...new_data, cityTerm: [applyEntityTransform(data.cityTerm)]};
  }
  if (data.commTerm) {
    new_data = {
      ...new_data,
      communityTerm: applyEntityTransform(data.commTerm)
    };
    delete new_data['commTerm'];
  }
  if (data.neighborhood) {
    new_data = {
      ...new_data,
      neighborhoodTerm: [applyEntityTransform(data.neighborhood)]
    };
    delete new_data['neighborhood'];
  }
  if (data.userRef) {
    new_data = {
      ...new_data,
      userRef: [applyEntityTransform(data.userRef, 'users')],
    };
    // delete new_data['attributes'];
  }

  return new_data;
};

/**
 * Transforms data from user entities.
 */
export const userDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM USER DATA: NO DATA LOADED");
    return data;
  }

  var internal = '/user/' + data.internalId;
  const env = process?.env?.NEXT_PUBLIC_BACKEND_URL || 'https://cms.eatonrealty.com';

  let new_user = {
    ...data,
    internalId: data['internalId'],
    id: data['id'],
    internal: internal,
    alias: data.rest_alias || data.path?.alias || internal || '',
    email: data.publicEmail || data.email || '',
    title: data.fullName || data.displayName || data['name'] || data['title'] || '',
    gmuser_name: data.fullName || data.displayName || data['name'] || data['title'] || '',
    gmuser_email: data.publicEmail || data.mail || '',
    gmuser_phone: data.gmuser_phone || (data.address && data.address[0]?.address_phonenumber) || '',
  };

  if (data?.image?.imageFile?.uri?.url) {
    new_user = {
      ...new_user,
      imageOrig: (env + data.image.imageFile.uri.url) || '',
      imgTitle: data.image?.relationships?.imageFile?.data?.meta?.title || data.title || new_user.title || '',
      imgAlt: data.image?.relationships?.imageFile?.data?.meta?.alt || data.title || new_user.title || '',
      imgHeight: data.image?.relationships?.imageFile?.data?.meta?.height || 0,
      imgWidth: data.image?.relationships?.imageFile?.data?.meta?.width || 0,
    };
    delete new_user['image'];
    delete new_user['attributes'];
  }

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }

  if (body_val && body_val.includes(`"json"`)) {
    new_user = {...new_user, body_json: JSON.parse(body_val) || {}};
  }
  else {
    new_user = {...new_user, body: body_val || ''};
  }

  if (data.roles?.length > 0) {
    let roles = [];
    data.roles.map((r) => {
      roles.push(r.internalId);
    });
    new_user = {...new_user, roles: roles};
  }
  if (data.boundaryRef?.length > 0) {
    let bound = [];
    data.boundaryRef.map((r) => {
      bound.push(nodeDrupalTransform(r));
    });
    new_user = {...new_user, boundaryRef: bound};
  }

  return new_user;
};

/**
 * Transforms data from taxonomies.
 */
export const taxonomyDrupalTransform = (data) => {
  if (!data) {
    console.warn("TRANSFORM TAXONOMY DATA: NO DATA LOADED");
    return data;
  }

  var internal = '/taxonomy/term/' + data.internalId;

  let new_term = {
    ...data,
    internalId: data['internalId'],
    id: data['id'],
    internal: internal,
    alias: data.rest_alias || data.path?.alias || internal || '',
    title: data['name'] || data['title'] || '',
    slides: data.slides || [],
    imageOrig: data.imageOrig || data.image || null,
  };

  let body_val = (data.body?.processed) ? data.body.processed : false;
  if (!body_val && data.body?.value) {
    body_val = data.body.value;
  }
  else if (!body_val) {
    body_val = data.body;
  }
  new_term = {...new_term, body: body_val || ''};

  if (data.slides?.length > 0) {
    let slides = [];
    if (data.slides[0].src !== undefined) {
      slides = data.slides;
    }
    else {
      slides = composeSliderMedia(data.slides);
    }
    new_term = {...new_term, slides: slides};
  }

  return new_term;
};

/**
 * Drupal entities data array into List of links (for SideNav component).
 */
export const trimBodyText = (data, length, ellipsis) => {
  if (!data || typeof data !== 'string') {
    console.warn("Invalid data.Has to be a string!");
    return data;
  }

  let new_data = '';
  length = length || 300;
  ellipsis = ellipsis || false;

  new_data = data.replace(/<[^>]+>/g, '').substring(0, length);
  if (ellipsis) {
    new_data += '...';
  }

  return new_data;
};

/**
 * Drupal entities data array into List of links (for SideNav component).
 */
export const entitiesArrayDrupalSidenav = (data) => {
  if (!data) {
    console.warn("TRANSFORM ENTITIES ARRAY: NO DATA LOADED");
    return data;
  }
  var new_data = [];

  data.map((val, key) => {
    new_data.push({
      id: val.internalId,
      linkUrl: val.alias || val.internal || '#',
      linkText: val.title || val.name || '',
    });
  });

  return new_data;
};

export const composeSliderMedia = (images) => {
  const img_arr = [];

  if (images) {
    const env = (process?.env.NEXT_PUBLIC_BACKEND_URL) ? process.env.NEXT_PUBLIC_BACKEND_URL : 'https://cms.eatonrealty.com';
    images.map((val, ind) => {
      if (val?.imageFile?.uri?.url) {
        img_arr.push({
          imgTitle: val?.relationships?.imageFile?.data?.meta?.title,
          imgAlt: val?.relationships?.imageFile?.data?.meta?.alt,
          imgHeight: val?.relationships?.imageFile?.data?.meta?.height,
          imgWidth: val?.relationships?.imageFile?.data?.meta?.width,
          src: env + val.imageFile.uri.url,
          image_styles: val.imageFile?.links || [],
        });
      }
    });
  }
  return img_arr;
};

/**
 * Transforms data from the backend into something more
 * beautiful and readable on the frontend.
 */
export const metadata = (data) => {
  let res = {};
  const attr = data && data.data ? data.data : data;
  if (attr) {
    res = {
      tags: (attr?.['#attached']?.html_head) || [],
    }
  }
  return (res);
};

export const pickImageStyle = (data, style, placeholder) => {
  style = style || 'max_510_height';
  let {
    imgAlt,
    imgTitle,
    imgWidth,
    imgHeight,
    imageOrig,
    image_styles,
    title,
  } = data;

  let imageStyled = null, result, isPlaceholderLogo = false;
  imgTitle = imgTitle || title;
  imgAlt = imgAlt || title;

  if (image_styles && Object.keys(image_styles).length > 0) {
    if (image_styles[style]?.href) {
      imageStyled = image_styles[style]?.href || null;
      imgWidth = image_styles[style]?.meta?.width || imgWidth || null;
      imgHeight = image_styles[style]?.meta?.height || imgHeight || null;
    }
    else if (image_styles?.orig_optimized?.href) {
      imageStyled = image_styles?.orig_optimized?.href || null;
      imgWidth = image_styles?.orig_optimized?.meta?.width || imgWidth || null;
      imgHeight =
        image_styles?.orig_optimized?.meta?.height || imgHeight || null;
    }
  }
  imageOrig = imageStyled ? imageStyled : imageOrig;
  if (!imageOrig && placeholder && placeholder !== false) {
    imageOrig = placeholder || '/assets/eaton-realty.svg';
    if (imageOrig == '/assets/eaton-realty.svg') {
      isPlaceholderLogo = true;
    }
  }
  result = { imgAlt, imgTitle, isPlaceholderLogo, imgWidth, imgHeight, imageOrig };
  return result;
};
