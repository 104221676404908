import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Head from "next/head";

export default function Custom403() {
  return (
    <>
      <Head>
        <title>Access Denied | Eaton Realty</title>
        <meta name="description"
              content="This page seems to have protected content. You don't have enough permissions to view this page."/>
        <meta name='robots' content='noindex,nofollow'/>
      </Head>
      <Box my={5}>
        <h1><strong>Page No Longer Available</strong></h1>
        <br/><br/>
        <Typography style={{fontSize: "2rem",}} component="p">This page seems to
          have protected content or is no longer available. But don't turn back,
          we'll find a great fit for you.<br/> Start searching <a
            href="/buy/homes-for-sale">Homes for Sale</a> or <a
            href="/rent/homes-for-rent">Homes for
            Rent</a></Typography><br/><br/>
        {/*<h6>@TODO: LOGIN FORM HERE??</h6>*/}
      </Box>
    </>
  );
}
