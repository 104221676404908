import Link from 'next/link';
import Box from '@material-ui/core/Box';
import Head from "next/head";

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Page not found | Eaton Realty</title>
        <meta name="description"
              content="This page content is not available any longer."/>
        <meta name='robots' content='noindex,nofollow'/>
      </Head>
      <Box my={5}>
        <h1>Oops... You got lost!</h1>
        <br/><br/>
        <h6>We may have packed this page!..</h6> <br/><br/>
        <h6> Use the navigation above or
          &nbsp;<Link href="/contact-us">
            <a>Contact Us</a>
          </Link>&nbsp; and we'll help you find what you were looking for.
        </h6>
      </Box>
    </>
  );
}
